import type { CalendarEvent } from '@/components/calendar/Calendar';

type UseDatesProps = {
    events: CalendarEvent[];
    selectedDates?: {
        to: Date | null;
        from: Date | null;
    };
    activeMonthDate: Date;
    today: Date;
    validMonthRange: [number, number];
};
export const useCalendarDates = ({ activeMonthDate, today, validMonthRange }: UseDatesProps) => {
    const year = activeMonthDate.getFullYear();
    const monthIndex = activeMonthDate.getMonth();
    const displayMonthNumber = monthIndex + 1 < 10 ? `0${monthIndex + 1}` : monthIndex + 1;
    const selectedPreviousMonthDate = new Date(activeMonthDate);
    selectedPreviousMonthDate.setMonth(selectedPreviousMonthDate.getMonth() - 1, 1);
    const selectedNextMonthDate = new Date(activeMonthDate);
    selectedNextMonthDate.setMonth(selectedNextMonthDate.getMonth() + 1, 1);
    const disallowedPreviousMonthDate = new Date(today);
    disallowedPreviousMonthDate.setMonth(today.getMonth() - Math.abs(validMonthRange[0]), 1);
    const disallowedFollowingMonthDate = new Date(today);
    disallowedFollowingMonthDate.setMonth(today.getMonth() + Math.abs(validMonthRange[1]), 1);

    return {
        displayMonthNumber,
        monthIndex,
        year,
        selectedPreviousMonthDate,
        selectedNextMonthDate,
        disallowedPreviousMonthDate,
        disallowedFollowingMonthDate,
    };
};
