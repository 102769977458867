import type { Money } from '../models/money';

export const formatMoney = (money: Money, decimals = 2): string =>
    `${thousandSeparated(setNumberOfDecimals(money.value, decimals))} ${formatCurrency(money.currency)}`;

const formatCurrency = (currency: string): string => (currency === 'NOK' ? 'kr' : currency);

// Format with non-breaking space (U+00A0), which can be written as ' ' or '\u00A0'
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const thousandSeparated = (value: any, space = '\u00A0'): string => value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, space) ?? '';

export const formatPhoneNumber = (value: string, space = '\u00A0'): string => {
    const countryCode = value.slice(0, 3);
    const digits = value.slice(3);
    let formatted = '';
    if (countryCode === '+47') {
        formatted = value.replace(/(\+\d{2})(\d{3})(\d{2})(\d{3})/, `$1${space}$2${space}$3${space}$4`);
    } else if (digits.length % 2 === 0) {
        formatted = value.replace(/(\d{2})/g, `$1${space}`).trimEnd();
    } else {
        formatted = value.replace(/(\+\d{2})(\d+)/, `$1${space}$2`);
    }
    return formatted;
};

export const setNumberOfDecimals = (value: number, decimals = 2): string =>
    (Math.round(value * 100) / 100).toLocaleString('no', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        useGrouping: false,
    });

export const validSeoPath = (str: string): string =>
    str
        .normalize('NFD') // decomposes combined graphemes
        // biome-ignore lint/suspicious/noMisleadingCharacterClass: This was set before biome was added
        .replace(/[.,'\u0300-\u036f]/g, '') // remove dots, commas, apostrophes and diacritics
        .replace(/\s/gm, '+') // replace spaces
        .replace(/\//g, '+') // replace slashes
        .replace(/[Ææ]/g, 'ae') // replace ÆØÅ
        .replace(/[Øø]/g, 'o')
        .replace(/[Åå]/g, 'a')
        .replace(/[Łł]/g, 'l') // replace weird polish L
        .toLowerCase();

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const formatDuration = (totalMinutes: any, minutesSuffix = false): string => {
    if (typeof totalMinutes !== 'number' || Number.isNaN(totalMinutes)) {
        return '-';
    }
    const hours = Math.floor(totalMinutes / 60);
    let minutes: number | string = totalMinutes % 60;
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (minutesSuffix && hours === 0) {
        return `${minutes}m`;
    }
    return `${hours}t ${minutes}${minutesSuffix ? 'm' : ''}`;
};
