import { formatISODate } from 'shared/util/datetime-fns';
import type { EventTypes } from '@/domain';

type ActiveEvent = {
    id: string;
    type: EventTypes;
    status: 'start' | 'between' | 'end' | 'start-end';
};
export type DateItem = {
    data: Record<string, string> | undefined;
    date: Date;
    states: {
        isCurrentMonth: boolean;
        isExpired: boolean;
        isFirstDayOfMonth: boolean;
        isLastDayOfMonth: boolean;
        isToday: boolean;
        isWeekend: boolean;
    } | null;
    activeEvents?: ActiveEvent[];
};

export type CalendarEvent = {
    id: ActiveEvent['id'];
    type: ActiveEvent['type'];
    startDate: Date;
    endDate: Date;
};

const createDateStatus = ({
    date: originalDate,
    startDate: originalStartDate,
    endDate: originalEndDate,
}): ActiveEvent['status'] | undefined => {
    const date = new Date(originalDate.valueOf());
    const startDate = new Date(originalStartDate.valueOf());
    const endDate = new Date(originalEndDate.valueOf());
    // Only compare date, not time
    [date, startDate, endDate].map((d) => d.setHours(0, 0, 0, 0));

    if (date.getTime() === startDate.getTime() && date.getTime() === endDate.getTime()) {
        return 'start-end';
    }

    if (date.getTime() === startDate.getTime()) {
        return 'start';
    }

    if (date.getTime() === endDate.getTime()) {
        return 'end';
    }

    if (date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime()) {
        return 'between';
    }
};

const createDateItem = ({
    activeMonth,
    date,
    dayDataByMonth,
    events = [],
    isWithStates,
    today,
}: {
    activeMonth: Date;
    date: Date;
    dayDataByMonth: Record<string, Record<string, string>>;
    events: CalendarEvent[];
    isWithStates: boolean;
    today: Date;
}): DateItem => {
    const isToday =
        date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    const isExpired = date < today && isToday === false;
    const isWeekend = date.getDay() === 5 || date.getDay() === 6;
    const isCurrentMonth = date.getMonth() === today.getMonth();
    const lastDayOfActiveMonth = new Date(activeMonth.getFullYear(), activeMonth.getMonth() + 1, 0).getDate();
    const isFirstDayOfMonth =
        activeMonth.getFullYear() === date.getFullYear() && activeMonth.getMonth() === date.getMonth() && date.getDate() === 1;
    const isLastDayOfMonth =
        date.getFullYear() === activeMonth.getFullYear() &&
        date.getMonth() === activeMonth.getMonth() &&
        date.getDate() === lastDayOfActiveMonth;

    const activeEvents: ActiveEvent[] = events.reduce((acc, event) => {
        const status = createDateStatus({ date, startDate: event.startDate, endDate: event.endDate });

        if (!status) {
            return acc;
        }

        const activeEvent: ActiveEvent = { id: event.id, type: event.type, status };
        acc.push(activeEvent);

        return acc;
    }, [] as ActiveEvent[]);

    const states = isWithStates
        ? {
              isCurrentMonth,
              isExpired,
              isToday,
              isWeekend,
              isFirstDayOfMonth,
              isLastDayOfMonth,
          }
        : null;

    // Set data from dayDataByMonth for the matching date
    const data = dayDataByMonth[formatISODate(date)];

    const dateItem: DateItem = {
        date,
        states,
        activeEvents,
        data,
    };

    return dateItem;
};

type GenerateDateArgs = {
    dayDataByMonth: Record<string, Record<string, string>>;
    events: CalendarEvent[];
    monthIndex: number;
    offsetStartDay: number;
    today: Date;
    year: number;
};
export function generateDateItems({ dayDataByMonth, events, monthIndex, today, year, offsetStartDay }: GenerateDateArgs): DateItem[] {
    const prevMonthIndex = monthIndex <= 0 ? 11 : monthIndex - 1;
    const nextMonthIndex = monthIndex >= 11 ? 0 : monthIndex + 1;
    const yearOfNextMonth = monthIndex >= 11 ? year + 1 : year;
    const yearOfPrevMonth = monthIndex <= 0 ? year - 1 : year;
    const totalDaysInWeek = 7;
    const activeMonth = new Date(year, monthIndex, 1);
    let firstDayOfWeekInMonthIndex = activeMonth.getDay();
    firstDayOfWeekInMonthIndex =
        firstDayOfWeekInMonthIndex - offsetStartDay < 0 ? totalDaysInWeek - 1 : firstDayOfWeekInMonthIndex - offsetStartDay;
    let lastDayOfWeekInMonthIndex = new Date(yearOfNextMonth, nextMonthIndex, 0).getDay();
    lastDayOfWeekInMonthIndex =
        lastDayOfWeekInMonthIndex - offsetStartDay < 0 ? totalDaysInWeek - 1 : lastDayOfWeekInMonthIndex - offsetStartDay;
    const totalDaysInPrevMonth = new Date(yearOfPrevMonth, monthIndex, 0).getDate();
    const totalDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    const prevPaddingDaysCount =
        firstDayOfWeekInMonthIndex === 0 ? null : totalDaysInPrevMonth - (totalDaysInPrevMonth - firstDayOfWeekInMonthIndex + 1);
    const nextPaddingDaysCount =
        lastDayOfWeekInMonthIndex === totalDaysInWeek - 1 ? null : totalDaysInWeek - (lastDayOfWeekInMonthIndex + 1);
    const prevPaddingDates: Date[] = [];
    const nextPaddingDates: Date[] = [];
    const datesInMonth: Date[] = [];

    if (prevPaddingDaysCount !== null) {
        for (let i = prevPaddingDaysCount; i >= 0; i--) {
            prevPaddingDates.push(new Date(yearOfPrevMonth, prevMonthIndex, totalDaysInPrevMonth - i));
        }
    }

    if (nextPaddingDaysCount !== null) {
        for (let i = 1; i <= nextPaddingDaysCount; i++) {
            nextPaddingDates.push(new Date(yearOfNextMonth, nextMonthIndex, i));
        }
    }

    for (let i = 1; i < totalDaysInMonth + 1; i++) {
        datesInMonth.push(new Date(year, monthIndex, i));
    }

    const prevPaddingDateItems = prevPaddingDates.map((item) =>
        createDateItem({ date: item, dayDataByMonth, events, isWithStates: false, today, activeMonth }),
    );
    const generalDateItems = datesInMonth.map((item) =>
        createDateItem({ date: item, dayDataByMonth, events, isWithStates: true, today, activeMonth }),
    );
    const nextPaddingDateItems = nextPaddingDates.map((item) =>
        createDateItem({ date: item, dayDataByMonth, events, isWithStates: false, today, activeMonth }),
    );

    return [...prevPaddingDateItems, ...generalDateItems, ...nextPaddingDateItems];
}
