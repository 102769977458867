import type { PriceCalendar, PriceOverride } from '@/domain';
import { queryString } from 'shared/util/query';
import getConfig from 'next/config';
import useFetch from 'shared/hooks/useFetch';
import useSWRMutation from 'swr/mutation';
import { postPriceOverrideData } from '@/api/api';
import { useSharedState } from 'shared/hooks/useSharedState';
import { formatISODate } from 'shared/util/datetime-fns';
import { mutate } from 'swr';
import { usePriceOverrideInvalidate } from '@/hooks/usePriceOverride';

const {
    publicRuntimeConfig: { apiExternalUrl },
} = getConfig();

const url = (adId: string, fromDate: string, toDate: string, guests = 1) =>
    `${apiExternalUrl}/calendar/priceprivate${queryString({
        adId: adId,
        fromDate: fromDate,
        toDate: toDate,
        guests: guests,
    })}`;

type DateFromTo = { fromDate: string; toDate: string };
const useSharedDateFromTo = () => useSharedState<DateFromTo>('PRICE_CALENDAR_DATES', { fromDate: '', toDate: '' });

export const usePriceCalendar = (adId: string, fromDate: Date, toDate: Date, guests = 1) => {
    const [date, setNewDate] = useSharedDateFromTo();

    const formattedFromDate = formatISODate(fromDate);
    const formattedToDate = formatISODate(toDate);
    if (!date) {
        throw new Error('Date not being set should not be possible');
    }

    if (date.fromDate !== formattedFromDate || date.toDate !== formattedToDate) {
        setNewDate({ fromDate: formattedFromDate, toDate: formattedToDate });
    }

    const { data: priceCalendar, ...rest } = useFetch<PriceCalendar>({
        url: url(adId, date.fromDate || formattedFromDate, date.toDate || formattedToDate, guests),
    });

    return { priceCalendar, ...rest };
};

const post = async (_, { arg }: { arg: PriceOverride }) => postPriceOverrideData(arg);

export const usePriceCalendarMutation = (adId: string) => {
    const [date] = useSharedDateFromTo();
    const { invalidate } = usePriceOverrideInvalidate(adId);

    if (!date) {
        throw new Error('Date not being set should not be possible');
    }

    const { trigger, ...rest } = useSWRMutation(url(adId, date.fromDate, date.toDate), post, { throwOnError: false });

    const triggerWithSideEffect = async (arg: PriceOverride) => {
        await trigger(arg);

        if (invalidate) {
            await invalidate();
        }
    };

    return { trigger: triggerWithSideEffect, ...rest };
};

export const usePriceCalendarInvalidate = (adId: string) => {
    const [date] = useSharedDateFromTo();

    if (!date) return {};

    const invalidate = () => mutate(url(adId, date.fromDate, date.toDate));
    return { invalidate };
};
