import type { PriceOverride } from '@/domain';
import useFetch from 'shared/hooks/useFetch';
import getConfig from 'next/config';
import useSWRMutation from 'swr/mutation';
import { deletePriceOverride } from '@/api/api';
import { usePriceCalendarInvalidate } from '@/hooks/usePriceCalendar';
import { mutate } from 'swr';

const {
    publicRuntimeConfig: { apiExternalUrl },
} = getConfig();

type DateFromTo = { itemId: string; startDate: string; endDate: string };

const url = (adId: string) => `${apiExternalUrl}/price/override/${adId}`;

export const usePriceOverride = (adId: string) => useFetch<PriceOverride[]>({ url: url(adId) });

const post = async (_url, { arg }: { arg: DateFromTo }) => deletePriceOverride(arg);

export const usePriceOverrideDelete = (adId: string) => {
    const { trigger, ...rest } = useSWRMutation(url(adId), post, { throwOnError: false });
    const { invalidate } = usePriceCalendarInvalidate(adId);

    const triggerWithSideEffect = async (arg: DateFromTo) => {
        await trigger(arg);

        if (invalidate) {
            await invalidate();
        }
    };

    return { trigger: triggerWithSideEffect, ...rest };
};

export const usePriceOverrideInvalidate = (adId: string) => {
    const invalidate = () => mutate(url(adId));
    return { invalidate };
};
