import useSWRImmutable from 'swr/immutable';

/**
 * Shared state value and setter hook.
 *
 * const [data, setData] = useSharedState('someKey', { foo: 'bar' });
 *
 * @param uniqueKey unique state/cache key
 * @param initialValue optional default value
 */
export const useSharedState = <T>(
    uniqueKey: string,
    initialValue: T | undefined = undefined,
): [T | undefined, (v: T | undefined) => void] => {
    const { data: state = initialValue, mutate: setState } = useSWRImmutable<T>(uniqueKey);

    return [state, setState];
};
