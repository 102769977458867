export const weekdayNames = {
    no: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

export const monthNames = {
    no: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Desember'],
};

export enum CountryCodes {
    NO = 'no',
    SE = 'se',
}

export enum Languages {
    EN = 'en',
    NO = 'no',
}

export enum Currencies {
    NOK = 'nok',
    SEK = 'sek',
}
