import useSWR, { type SWRConfiguration } from 'swr';

type StatusCodeHandlers<T> = { [key: number | string]: (result: Response) => T | never };
interface FetcherOptions<T> {
    isEnabled: boolean;
    statusCodeHandlers?: StatusCodeHandlers<T>;
}

export class UseFetchError extends Error {
    status: number;
    constructor(message: string, code: number) {
        super(message);
        this.name = 'UseFetchError';
        this.status = code;
    }
}

const fetcher =
    <T>({ statusCodeHandlers = {}, isEnabled = true }: FetcherOptions<T>) =>
    async (key: string | [string, HeadersInit]): Promise<T> => {
        if (!isEnabled) {
            return undefined as T;
        }
        let url: string;
        let headers: HeadersInit;

        if (Array.isArray(key)) {
            url = key[0];
            headers = key[1];
        } else {
            url = key;
            headers = {};
        }

        const res = await fetch(url, { headers });
        // Status code is matched exactly
        if (statusCodeHandlers[res.status]) {
            return statusCodeHandlers[res.status](res);
        }

        // Wildcard manual 2xx match
        if (statusCodeHandlers['2xx'] && res.status >= 200 && res.status <= 299) {
            return statusCodeHandlers['2xx'](res);
        }

        // Wildcard 4xx match
        if (statusCodeHandlers['4xx'] && res.status >= 400 && res.status <= 499) {
            return statusCodeHandlers['4xx'](res);
        }

        // Wildcard 5xx match
        if (statusCodeHandlers['5xx'] && res.status >= 500 && res.status <= 599) {
            return statusCodeHandlers['5xx'](res);
        }

        // 2xx match
        if (res.ok) {
            return res.json();
        }

        throw new UseFetchError(`Something went wrong with useFetch: ${res.status}`, res.status);
    };

interface UseFetchOptions<T> {
    isEnabled?: boolean;
    isImmutable?: boolean;
    url: string | [string, HeadersInit];
    statusCodeHandlers?: StatusCodeHandlers<T>;
    options?: SWRConfiguration;
}

const useFetch = <T>({ isEnabled = true, isImmutable = false, statusCodeHandlers, options, url }: UseFetchOptions<T>) => {
    const updatedOptions = {
        ...options,
        ...(isImmutable
            ? {
                  revalidateIfStale: false,
                  revalidateOnFocus: false,
                  revalidateOnReconnect: false,
              }
            : {}),
    };

    return useSWR<T, UseFetchError>(url, fetcher<T>({ statusCodeHandlers, isEnabled }), updatedOptions);
};

export default useFetch;
