import { thousandSeparated } from 'shared/util/formatting';

const resolveCurrencySuffix = (currencyCode: string | null) => {
    switch (currencyCode) {
        case 'SEK':
            return 'kr';
        case 'DKK':
            return 'kr';
        case 'ISK':
            return 'kr';
        case 'EUR':
            return '€';
        case 'GBP':
            return '£';
        case 'USD':
            return '$';
        case 'CAD':
            return '$';
        case 'JPY':
            return '¥';
        default:
            return 'kr';
    }
};

export interface Money {
    currency: string;
    value: number;
}

export const requiredFields = ['currency', 'value'];

export const getDisplayCurrency = ({ currency, value }: Money): string => {
    const currencySymbol = resolveCurrencySuffix(currency);
    if (currencySymbol === 'kr') {
        return `${thousandSeparated(value)} ${currencySymbol}`;
    }
    return `${currencySymbol}${thousandSeparated(value)}`;
};

export const createMoney = (data: Record<string, unknown>): Money => {
    const money: Partial<Money> = {};

    for (const fieldName of requiredFields) {
        if (!(Object.prototype.hasOwnProperty.call(data, fieldName) && typeof data[fieldName] !== 'undefined')) {
            throw new Error(`createMoney: "${fieldName}" is required`);
        }

        money[fieldName] = data[fieldName];
    }

    return {
        ...(money as Money),
    };
};
