import type { ObjectData } from '@/domain';
import useSWRImmutable from 'swr/immutable';

export const USE_OBJECT_KEY = 'objectDataKey';

export const useObject = (): ObjectData => {
    const { data } = useSWRImmutable<ObjectData>(USE_OBJECT_KEY);
    if (!data) {
        throw new Error('ObjectData is null, which should not be possible!');
    }
    return data;
};
