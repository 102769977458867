import { type Languages, weekdayNames } from '../../constants';

type WeekdayHeaderProps = {
    language: Languages;
    offsetStartDay: number;
};

export const WeekdayHeader = ({ language, offsetStartDay }: WeekdayHeaderProps) => {
    const weekdayNamesArr = [...weekdayNames[language]];

    for (let i = 0; i < offsetStartDay; i++) {
        const weekdayName: string = weekdayNamesArr.shift() ?? '';

        weekdayNamesArr.push(weekdayName);
    }

    return (
        <>
            {weekdayNamesArr.map((weekdayName) => (
                <h3 className="Calendar-columnTitleItem" key={weekdayName}>
                    <abbr className="Calendar-columnTitleText" title={weekdayName}>
                        {weekdayName.slice(0, 3)}
                    </abbr>
                </h3>
            ))}
        </>
    );
};
